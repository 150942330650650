var $ = require('jquery');
var _ = require('lodash');

window.gotoHome = function() {
  window.location.href = '/';
};

var setMenuPointer = function(element) {
  // current page elements
  var menuPointer = $('#menu-pointer');
  var currentPageItem = $(
    '.nav-primary .current_page_item, .current_page_ancestor',
  ).first();
  var currentPageChildren = $(
    '.nav-primary .current_page_ancestor ul.children, .current_page_item ul.children',
  );

  // overwrite if custom element is specified
  currentPageItem = element || currentPageItem;
  currentPageChildren =
    (element && element.find('ul.children')) || currentPageChildren;

  $('.nav-primary')
    .find('ul.children')
    .hide();
  menuPointer.hide();

  var menuItemWidth = currentPageItem.width();
  menuPointer.css('margin-left', menuItemWidth / 2 - 10 + 'px');
  menuPointer.prependTo(currentPageItem);

  if (currentPageItem.length) {
    menuPointer.toggle(currentPageChildren.length > 0);
    currentPageChildren.show();

    // Insert the parent page in its submenu
    if (currentPageChildren.find('li.is_parent').length === 0) {
      const parentItem = $('<li>')
        .addClass('page_item is_parent')
        .append(
          $('<a>')
            .attr('href', currentPageItem.find('a').attr('href'))
            .html(currentPageItem.find('a').html()),
        )
        .toggleClass(
          'current_page_item',
          currentPageChildren.find('.current_page_item').length === 0 &&
            currentPageItem.hasClass('current_page_item'),
        );

      currentPageChildren.prepend(parentItem);
    }
  }
};

function lerp(fromValue, toValue, factor) {
  return fromValue + (toValue - fromValue) * factor;
}

function updateHeaderImage(e) {
  var headerImageElm = $('#header-image-container');
  var photooffset = Number(
    $('#header-image-container').attr('data-photooffset') || 0,
  );
  var contentElm = headerImageElm.find('.content');
  var contentHeight = contentElm.outerHeight();
  var containerHeight = headerImageElm.outerHeight();
  var offset = contentHeight - containerHeight;

  if (offset < 0) {
    return;
  }

  var scrollTop = $(window).scrollTop();
  var adjustment = lerp(0, offset, photooffset);

  var marginTop = scrollTop / 2 - adjustment;
  contentElm.css('margin-top', marginTop + 'px');
}

function bindEvents() {
  $('#menu-icon').on('click', function() {
    $('body').toggleClass('menu-open');
  });

  if (!$('#header-image-container').hasClass('no-image')) {
    var throttled = _.throttle(updateHeaderImage, 1);
    $(window).on('scroll', throttled);
    $(window).on('resize', throttled);
    $(window).on('resize', function(evt) {
      setMenuPointer();
    });
    updateHeaderImage();
  }
}

$(window).load(function() {
  $('html').addClass('window-loaded');
  bindEvents();
});

$(document).ready(function() {
  $('.nav-primary .menu > ul > li.page_item_has_children').hover(
    function() {
      setMenuPointer($(this));
    },
    function() {
      setMenuPointer();
    },
  );
  setMenuPointer();
});
